<template>
  <div>
    <div style="background-color: #008c83; width: 100%; height: 40px; line-height: 40px">
      <ul class="mune_ul">
        <li><router-link to="/">首页</router-link></li>
        <li><router-link to="/news">行业动态</router-link></li>
        <li><router-link to="/learnModel">学会要闻</router-link></li>
        <li @mouseenter="enterHover(0)" @mouseleave="leaveHover(0)">
          <router-link to="#">学术活动</router-link>
          <ul class="cn_menu02" v-if="hoverxs">
            <li><router-link to="/studyActivityInner">国内活动</router-link></li>
            <li><router-link to="/studyActivity">国际活动</router-link></li>
          </ul>
        </li>

        <li @mouseenter="enterHover(1)" @mouseleave="leaveHover(1)">
          <router-link to="#">隧道工程</router-link>
          <ul class="cn_menu02" v-if="hoversd">
            <li><router-link to="/sdinner">中国隧道</router-link></li>
            <li><router-link to="/sdoutside">国际隧道</router-link></li>
          </ul>
        </li>
        <li><router-link to="/bigNews">大事记</router-link></li>
        <li><router-link to="/cardQuery">证件查询</router-link></li>
        <li><router-link to="/aboutUs">联系我们</router-link></li>
      </ul>
    </div>

    <!-- <router-link to="/">首页</router-link>
            <router-link to="/news">行业动态</router-link>
            <router-link to="/learnModel">学会要闻</router-link>
            <router-link to="/studyActivity">学术活动</router-link>
            <router-link to="/tunnelWorks">隧道工程</router-link>
            <router-link to="/bigNews">大事记</router-link>
            <router-link to="/cardQuery">证件查询</router-link>
            <router-link to="/aboutUs">联系我们</router-link> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      hoversd: false,
      hoverxs: false,
    }
  },
  methods: {
    enterHover(el) {
      switch (el) {
        case 0:
          this.hoverxs = true
          break
        case 1:
          this.hoversd = true
          break
      }
    },
    leaveHover(el) {
      switch (el) {
        case 0:
          this.hoverxs = false
          break
        case 1:
          this.hoversd = false
          break
      }
    },
  },
}
</script>

<style scoped>
a {
  color: #fff;
}

.ashw {
  color: aqua;
}

ul {
  list-style: none;
}


.mune_ul {
  width: 1000px;
  display: flex;
  justify-content: space-between;
  align-content: center;
  margin: 0 auto;
}

.mune_ul>li {
  width: 11%;
  position: relative;
}

.mune_ul>li:hover {
  /* background: linear-gradient(to top, #0c4846, #008c83); */

}

.mune_ul>li:hover>a {
  color: #ff0;
}

.mune_ul>li>a {
  width: 100%;
}

ul.cn_menu02 {
  z-index: 9999999;
  background: #0c4846;
  /* display: none; */
  position: absolute;
  left: 0;
  z-index: 99;
  padding: 0;
  display: flex;
  flex-direction: column;
}


ul.cn_menu02 li {
  z-index: 9999999;
  width: 105px;
  height: 40px;
  background: #0c4846;
  line-height: 40px;
  text-align: center;
  border-bottom: 1px solid #588;
  float: none;
}

ul.cn_menu02 li:hover a {
  color: #ff0;
}

ul.cn_menu02 li a,
ul.cn_menu02 li a:visited {
  width: 105px;
  height: 40px;
  font-size: 12px;
  font-weight: bold;
  line-height: 40px;
  text-align: center;
  color: #fff;
  display: block;
}

.mune_ul>li>a {
  color: #ffffff !important;
  font-size: 14px;
  font-weight: bold;
  line-height: 40px;
  text-align: center;
}

::v-deep .mune_ul>li>a:hover {
  color: #ff0 !important;
}
</style>