<template>
  <div class="clswid">
    <img src="https://www.cces-tuwb.com/templets/default/images/banner.jpg" width="1000px" />
    <div class="pages_con">
      <div class="pages_conl">
        <div class="pages_conl_crumbs">
          您的位置:&nbsp;<a href="/">首页</a> &gt; <a href="javascript:;">行业动态</a> &gt; 列表
        </div>
        <div class="industrytrends_list">
          <ul class="publishing_list_ul">
            <li>
              <a href="javascript:;" target="_blank" title="广湛高铁建设取得重大进展，最长隧道公岭隧道顺利贯通">
                <span>[2024-08-01](点击<b>86</b>)</span>
                •广湛高1铁建设取得重大进展，最长隧道公岭隧道顺利贯通</a>
            </li>
            <li>
              <a href="javascript:;" target="_blank" title="春风隧道全线贯通！">
                <span>[2024-08-01](点击<b>181</b>)</span>
                •春风隧道全线贯通！</a>
            </li>
            <li>
              <a href="javascript:;" target="_blank" title="全球最大直径TBM助力格鲁吉亚南北走廊KK公路古多里隧道提前贯通">
                <span>[2024-05-06](点击<b>76</b>)</span>
                •全球最大直径TBM助力格鲁吉亚南北走廊KK公路古多里隧道提前贯通</a>
            </li>
            <li>
              <a href="javascript:;" target="_blank" title="华南地区在建最大直径盾构隧道——春风隧道胜利贯通">
                <span>[2024-04-29](点击<b>111</b>)</span>
                •华南地区在建最大直径盾构隧道——春风隧道胜利贯通</a>
            </li>
            <li>
              <a href="javascript:;" target="_blank" title="2024年世界隧道大会在深圳开幕">
                <span>[2024-04-23](点击<b>56</b>)</span>
                •2024年世界隧道大会在深圳开幕</a>
            </li>
            <li>
              <a href="javascript:;" target="_blank" title="攻克“四高”难题 祁连山2号隧道今日全线贯通">
                <span>[2024-01-31](点击<b>144</b>)</span>
                •攻克“四高”难题 祁连山2号隧道今日全线贯通</a>
            </li>
            <li>
              <a href="javascript:;" target="_blank" title="渭源至武都高速公路木寨岭特长隧道今日通车运营 G75兰州至海口高速公路全线贯通">
                <span>[2024-01-31](点击<b>126</b>)</span>
                •渭源至武都高速公路木寨岭特长隧道今日通车运营 G75兰州至海口高速公路全线贯通...</a>
            </li>
            <li>
              <a href="javascript:;" target="_blank" title="深圳首次！双通道泥水盾构开始双线掘进">
                <span>[2024-01-26](点击<b>112</b>)</span>
                •深圳首次！双通道泥水盾构开始双线掘进</a>
            </li>
            <li>
              <a href="javascript:;" target="_blank" title="2023年第九届ITA大奖揭晓，中国荣获3项大奖">
                <span>[2023-11-27](点击<b>196</b>)</span>
                •2023年第九届ITA大奖揭晓，中国荣获3项大奖</a>
            </li>
            <li>
              <a href="javascript:;" target="_blank" title="深圳首台双通道泥水平衡盾构机在深大城际顺利始发">
                <span>[2023-11-08](点击<b>119</b>)</span>
                •深圳首台双通道泥水平衡盾构机在深大城际顺利始发</a>
            </li>
            <li>
              <a href="javascript:;" target="_blank" title="衢丽铁路I标项目枫树岭隧道莲林斜井至白岸口横洞正洞贯通">
                <span>[2023-11-08](点击<b>81</b>)</span>
                •衢丽铁路I标项目枫树岭隧道莲林斜井至白岸口横洞正洞贯通</a>
            </li>
            <li>
              <a href="javascript:;" target="_blank" title="青岛胶州湾第二海底隧道工程进入盾构施工准备阶段">
                <span>[2023-05-09](点击<b>173</b>)</span>
                •青岛胶州湾第二海底隧道工程进入盾构施工准备阶段</a>
            </li>
            <li>
              <a href="javascript:;" target="_blank" title="世界最大断面矩形盾构顶管机“中铁 1179 号”用于深圳地铁">
                <span>[2023-05-09](点击<b>178</b>)</span>
                •世界最大断面矩形盾构顶管机“中铁 1179 号”用于深圳地铁</a>
            </li>
            <li>
              <a href="javascript:;" target="_blank" title="我国东北地区首条穿海地铁线路正式通车运营！">
                <span>[2023-03-21](点击<b>105</b>)</span>
                •我国东北地区首条穿海地铁线路正式通车运营！</a>
            </li>
            <li>
              <a href="javascript:;" target="_blank" title="中铁隧道局在格鲁吉亚引领TBM施工">
                <span>[2023-03-21](点击<b>53</b>)</span>
                •中铁隧道局在格鲁吉亚引领TBM施工</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="pages_conr">
        <div class="pages_conr_tit02 txt_c">通知公告</div>
        <div class="pages_conr_ul">
          <ul class="pages_notice_announcemen_ul">
            <li>
              <a href="javascript:;">•中国土木工程学会隧道及地下工程分会 第十一届理事会名誉职务人员<span>2024-07-02</span></a>
            </li>
            <li>
              <a href="javascript:;">•中国土木工程学会隧道及地下工程分会 第十一届理事会组成名单 （2024 年 6 月 23 日通...<span>2024-07-02</span></a>
            </li>
            <li>
              <a href="javascript:;">•关于召开第十一届换届大会暨第七期隧道及地下工程科技创新讲坛的通知<span>2024-05-22</span></a>
            </li>
            <li>
              <a href="javascript:;">•关于第十届国际隧道与地下空间协会（ITA）隧道工程奖申报的通知<span>2024-04-26</span></a>
            </li>
            <li>
              <a href="javascript:;">•关于召开第十届第十次常务理事(扩大)会议的通知<span>2024-01-29</span></a>
            </li>
          </ul>
        </div>

        <div class="pages_conr_ad">
          <a href="javascript:;" target="_blank"><img
              src="https://www.cces-tuwb.com/templets/default/images/page_conr_meeting.jpg" /></a>
        </div>
      </div>
    </div>

    <div style="width: 1024px">
      <div style="
          display: flex;
          justify-content: space-between;
          height: 40px;
          line-height: 40px;
          background-color: #7f899b;
          padding: 0 30px;
        ">
        <div style="color: #ffffff">友情链接</div>
        <div style="color: #ffffff">更多 ></div>
      </div>
      <div style="background-color: #f0f0f0; height: 130px; display: flex; justify-content: space-around">
        <div style="margin-top: 20px; display: flex; flex-direction: column">
          <img src="https://www.cces-tuwb.com/uploads/flink/1510011624512233.jpg" width="190px" height="60px" />
          <a href="http://123.57.212.98/html/tm/" target="“_blank”">中国土木工程协会</a>
        </div>
        <div style="margin-top: 20px; display: flex; flex-direction: column">
          <img src="https://www.cces-tuwb.com/uploads/flink/1512010816529230.png" width="190px" height="60px" />
          <a href="http://www.ita-aites.org/" target="“_blank”">国籍隧道与地下空间协会</a>
        </div>
        <div style="margin-top: 20px; display: flex; flex-direction: column">
          <img src="https://www.cces-tuwb.com/uploads/flink/1703301645581811.png" width="190px" height="60px" />
          <a href="http://meeting.mof.gov.cn/" target="“_blank”">党政机关会议定点场所管理信</a>
        </div>
        <div style="margin-top: 20px; display: flex; flex-direction: column">
          <img src="	https://www.cces-tuwb.com/uploads/flink/20180322114045.jpg" width="190px" height="60px" />
          <a href="http://www.crtg.com/" target="“_blank”">中铁隧道局集团有限公司</a>
        </div>
        <div style="margin-top: 20px; display: flex; flex-direction: column">
          <img src="https://www.cces-tuwb.com/uploads/flink/1510011658244539.jpg" width="190px" height="60px" />
          <a href="http://www.swi.com.cn/" target="“_blank”">中铁西南科学研究所</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewS',
  data() {
    return {}
  },
  methods: {},
}
</script>

<style scoped>
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
button,
textarea,
p,
blockquote,
th,
td,
img {
  padding: 0px;
  margin: 0px;
  vertical-align: bottom;
  word-wrap: break-word;
}

.clswid {
  width: 1000px;
  margin: 0 auto;
  font-family: "\5B8B\4F53", Arial, "Microsoft Yahei", sans-serif;
}

.pages_con {
  width: 1000px;
  margin: 20px auto 20px;
  overflow: hidden;
}

.pages_conl {
  width: 750px;
  padding-right: 39px;
  border-right: 1px solid #eee;
  float: left;
  overflow: hidden;
}

.pages_conl_crumbs {
  width: 710px;
  height: 30px;
  background: #f5f5f5;
  line-height: 30px;
  color: #777;
  padding: 0px 10px;
  text-align: left;
}

a:visited {
  font-size: 12px;
  color: #333;
  font-weight: normal;
  text-decoration: none;
}

a:link {
  font-size: 12px;
  color: #333;
  font-weight: normal;
  text-decoration: none;
}

a {
  outline: none;
  cursor: pointer;
}

.industrytrends_list {
  width: 710px;
  padding-top: 10px;
  border-top: 1px solid #ddd;
  overflow: hidden;
}

ul {
  list-style: none;
}

ul.publishing_list_ul li {
  width: 710px;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px dashed #ddd;
  overflow: hidden;
}

ul.publishing_list_ul li a {
  width: 710px;
  height: 40px;
  font-size: 14px;
  line-height: 40px;
  display: block;
  text-align: left;
}

ul.publishing_list_ul li a span {
  color: #999;
  font-size: 12px;
  float: right;
}

ul.publishing_list_ul li a span b {
  color: #d00;
  font-weight: normal;
}

.pages_conr {
  width: 230px;
  float: right;
  overflow: hidden;
}

.txt_c {
  text-align: center;
}

.pages_conr_tit02 {
  width: 230px;
  height: 37px;
  background: #f5f5f5;
  font-size: 14px;
  font-weight: bold;
  line-height: 37px;
  color: #004848;
  border-top: 2px solid #004848;
  border-bottom: 1px solid #ddd;
}

.pages_conr_ul {
  width: 230px;
  padding-bottom: 30px;
  border-bottom: 1px solid #eee;
  overflow: hidden;
}

ul.pages_notice_announcemen_ul {
  background: #f5f5f5;
}

ul.pages_notice_announcemen_ul li {
  width: 230px;
  min-height: 80px;
  line-height: 20px;
  border-bottom: 2px solid #fff;
  overflow: hidden;
}

ul.pages_notice_announcemen_ul li a {
  width: 230px;
  min-height: 60px;
  padding: 10px 10px 10px 15px;
  display: block;
  text-align: left;
}

ul.pages_notice_announcemen_ul li a span {
  color: #d00;
}

.pages_conr_ad {
  width: 230px;
  padding-bottom: 30px;
  border-bottom: 1px solid #eee;
  overflow: hidden;
  margin-top: 30px;
}
</style>