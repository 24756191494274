<template>
    <div> 
    
        <img src="../assets/image.png" />
    </div>
    
</template>

<script>
export default {
  
}
</script>

<style scoped>

</style>
