// import BodyModel from '@/components/BodyModel.vue'
// import FootModel from '@/components/FootModel.vue'
// import HeadModel from '@/components/HeadModel.vue'
// import MuneModel from '@/components/MuneModel.vue'
import Router from 'vue-router'
import home from '@/view/home.vue'
import news from '@/view/news.vue'
import Vue from 'vue'

Vue.use(Router)

export default new Router({
  mode: 'history', // 使用 HTML5 History 模式
  routes: [
    {
      path: '/',
      name: 'home',
      component: home,
      meta: {
        title: '首页',
      },
    },
    {
      path: '/news',
      name: 'news',
      component: news,
      meta: {
        title: '行业动态',
      },
    },
    {
      path: '/cardQuery',
      name: 'cardQuery',
      component: () => import('@/view/cardQuery.vue'),
      meta: {
        title: '证件查询',
      },
    },
    {
      path: '/learnModel',
      name: 'learnModel',
      component: () => import('@/view/learnModel.vue'),
      meta: {
        title: '学术要闻',
      },
    },

    // {
    //   path: '/studyActivity',
    //   name: 'studyActivity',
    //   component: () => import('@/view/studyActivity'),
    //   meta: {
    //     title: '学术活动',
    //   },
    //   children: [
   
    //   ],
    // },
    {
      path: '/studyActivityInner',
      name: 'studyActivityInner',
      component: () => import('@/view/studyActivityInner.vue'),
      meta: {
        title: '国内活动',
      },
  },
    {
      path: '/studyActivity',
      name: 'studyActivity',
      component: () => import('@/view/studyActivity.vue'),
      meta: {
        title: '国外活动',
      },
    },
    {
      path: '/sdinner',
      name: 'sdinner',
      component: () => import('@/view/tunnelWorks.vue'),
      meta: {
        title: '中国隧道',
      },
    },
    {
      path: '/sdoutside',
      name: 'sdoutside',
      component: () => import('@/view/tunnelWorksOutside.vue'),
      meta: {
        title: '国际隧道',
      },
    },

    {
      path: '/bigNews',
      name: 'bigNews',
      component: () => import('@/view/bigNews.vue'),
      meta: {
        title: '大事记',
      },
    },
    {
      path: '/aboutUs',
      name: 'aboutUs',
      component: () => import('@/view/aboutUs.vue'),
      meta: {
        title: '大事记',
      },
    },
  ],
})
