<template>
    <div
        style="height: 150px; width: 100%;background-color: aqua;background: url(https://www.cces-tuwb.com/templets/default/images/cn_foot.jpg) repeat-x;margin-top: 20px;">
        <div style="display: flex;flex-direction: column;width: 1024px;margin: 0 auto;">
            <div style="display: flex;flex-direction: column;text-align: right;margin-top: 20px;">
                <span style="font-size: 14px;font-weight: 600;">
                    中国土木工程学会隧道及地下工程分会
                </span>
                <span>
                    地址：广东省广州市南沙区工业四路西侧电话：020-32268653 传真:020-32268651
                </span>
                <span>
                    E-mail: tuwbcces@21cn.com 邮编:511458
                </span>
                <span>
                    版权所有 © 中国土木工程学会隧道及地下工程分会 <span style="text-decoration: underline">豫ICP备06004005号</span>
                </span>
            </div>
        </div>
    </div>

</template>

<script>
export default {

}
</script>

<style scoped>
span {
    color: #ffffff;
    margin: 3px 0;
    font-size: 12px;
}
</style>
