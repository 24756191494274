<template>
    <div>
        <BodyModel />
    </div>
</template>

<script>
import BodyModel from '@/components/BodyModel.vue';
// import FootModel from '@/components/FootModel.vue';
// import HeadModel from '@/components/HeadModel.vue';
// import MuneModel from '@/components/MuneModel.vue';
export default {
  name: 'HomePage',
  components: { 
    // HeadModel,
    // MuneModel,
    BodyModel,
    // FootModel
  }
}
</script>

<style scoped>
span{
    color:#ffffff;
    margin:  3px 0;
    font-size: 12px;
}

</style>
