<template>
  <div id="app">
    <!-- <router-view></router-view>  -->
    <div>
      <header>
        <HeadModel />
        <nav>
          <MuneModel />
          <!-- 这里可以放导航链接 -->
          <!-- <router-link to="/">Home</router-link>
                <router-link to="/about">About</router-link> -->
        </nav>
      </header>
      <main>
        <!-- 这个插槽将被每个页面的具体内容替代 -->
        <router-view></router-view>
      </main>
      <footer>
        <!-- 页脚内容 -->
        <FootModel />
      </footer>
    </div>
  </div>
</template>

<script>
import HeadModel from './components/HeadModel.vue'
import MuneModel from './components/MuneModel.vue'
// import BodyModel from './components/BodyModel.vue'
import FootModel from './components/FootModel.vue'

export default {
  name: 'App',
  components: {
    HeadModel,
    MuneModel,
    // BodyModel,
    FootModel
  }
}
</script>

<style >


#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
