<template>
    <div class="cls">
        <img src="https://www.cces-tuwb.com/templets/default/images/banner.jpg" width="1000px" />
        <div style="display: flex;width: 1000px;text-align:center;margin-top: 10px;">
            <div style="width: 60%;">
                <a-carousel arrows dots-class="slick-dots slick-thumb" :dots="false" :autoplay="true"
                    style="width: 588px;height: 400px;">
                    <img src="https://www.cces-tuwb.com/uploads/allimg/180529/144IA0Y-0-lp.jpg" width="560px"
                        height="400px" />
                    <img src="https://www.cces-tuwb.com/uploads/allimg/170502/1-1F5021HI2P8-lp.jpg" width="560px"
                        height="400px" />
                    <img src="https://www.cces-tuwb.com/uploads/151029/1-151029145GN52.png" width="560px"
                        height="400px" />
                    <img src="https://www.cces-tuwb.com/uploads/allimg/160527/1_052G151191O4.jpg" width="560px"
                        height="400px" />
                </a-carousel>
            </div>
            <div style="width: 40%;">
                <div>
                    <div
                        style="display: flex;justify-content: space-between; height: 40px;line-height: 40px;background-color: #eeeeee;flex-direction: row-reverse;border-top: 2px solid #004848;">
                        <!-- <div class="industry_trends_tit">行业动态1</div> -->
                        <div class="industry_trends_tit"><a href="#" target="_blank">更多&nbsp;&gt;</a>行业动态</div>
                    </div>

                    <div>
                        <tr class="trcls">
                            <th class="thcls">•广湛高铁建设取得重大进展，最长隧道公岭隧道顺利贯
                            </th>
                            <th class="th2cls">2024-08-01</th>
                        </tr>
                        <tr class="trcls">
                            <th class="thcls">•春风隧道全线贯通！
                            </th>
                            <th class="th2cls">2024-08-01</th>
                        </tr>
                        <tr class="trcls">
                            <th class="thcls">•全球最大直径TBM助力格鲁吉亚南北走廊KK公路古多里隧
                            </th>
                            <th class="th2cls">>2024-05-06</th>
                        </tr>
                        <tr class="trcls">
                            <th class="thcls">•华南地区在建最大直径盾构隧道——春风隧道胜利贯通
                            </th>
                            <th class="th2cls">2024-04-29</th>
                        </tr>
                        <tr class="trcls">
                            <th class="thcls">•2024年世界隧道大会在深圳开幕
                            </th>
                            <th class="th2cls">2024-04-23</th>
                        </tr>
                        <tr class="trcls">
                            <th class="thcls">•攻克“四高”难题 祁连山2号隧道今日全线贯通
                            </th>
                            <th class="th2cls">2024-01-31</th>
                        </tr>
                        <tr class="trcls">
                            <th class="thcls">•渭源至武都高速公路木寨岭特长隧道今日通车运营G7...
                            </th>
                            <th class="th2cls">2024-01-31</th>
                        </tr>
                        <tr class="trcls">
                            <th class="thcls">•深圳首次！双通道泥水盾构开始双线掘进
                            </th>
                            <th class="th2cls">2024-01-26</th>
                        </tr>
                        <tr class="trcls">
                            <th class="thcls">•2023年第九届ITA大奖揭晓，中国荣获3项大奖
                            </th>
                            <th class="th2cls">2023-11-27</th>
                        </tr>
                        <tr class="trcls">
                            <th class="thcls">•深圳首台双通道泥水平衡盾构机在深大城际顺利始发
                            </th>
                            <th class="th2cls">2023-11-08</th>
                        </tr>
                    </div>
                </div>
            </div>
        </div>
        <div style="display: flex;width: 1000px;text-align:center;margin-top: 10px;">
            <div style="width: 60%;height: 470px;margin-right: 10px;padding-right: 6px;">
                <div
                    style="display: flex;justify-content: space-between; height: 40px;line-height: 40px;background-color: #eeeeee;flex-direction: row-reverse;border-top: 2px solid #004848;">
                    <div class="industry_trends_tit" style="width:580px ;"><a href="#"
                            target="_blank">更多&nbsp;&gt;</a>学会要闻</div>
                </div>
                <div style="display: flex; border-bottom: 1px #dddddd dashed;">
                    <div style="width: 30%;"><img
                            src="https://www.cces-tuwb.com/uploads/allimg/240806/1-240P61JJ3920-lp.jpg" width="170px"
                            height="140px" /></div>
                    <div style="width: 70%;margin: 5px;font-weight: bold;">
                        <span style="color:#0e5e5e;font-size: 12px;float: left;">第二十二届海峡两岸隧道与地下工程学术与技术研讨会胜利召开</span>
                        <div style="margin-top: 40px;text-align: left;">
                            <span style="font-size: 11px;color:#666666;font-weight: 100;">
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2024年8月2日-4日,第二十二届海峡两岸隧道与地下工程学术与技术研讨会在新疆乌鲁木齐隆重召开。来自大陆方面岩石力学与工程学会地下工程分会、土木工程学会隧道及地下工程分会和台湾方面隧道协会的专家，来自海峡两岸的专家、学者、教授、工程一线的技术人员和学生...[2024-08-06]
                            </span>
                        </div>
                    </div>
                </div>
                <div>
                    <tr class="trcls">
                        <th class="thcls">•中国土木工程学会隧道及地下工程分会第十一届理事会换届大会
                        </th>
                        <th class="th2cls">2024-06-24</th>
                    </tr>
                    <tr class="trcls">
                        <th class="thcls">•隧道及地下工程分会十届十次常务理事（扩大）会议顺利召开
                        </th>
                        <th class="th2cls">2024-03-12</th>
                    </tr>
                    <tr class="trcls">
                        <th class="thcls">•2024年唐忠理事长新年贺词
                        </th>
                        <th class="th2cls">2024-12-30</th>
                    </tr>
                    <tr class="trcls">
                        <th class="thcls">•第六期隧道及地下工程科技创新讲坛成功举办
                        </th>
                        <th class="th2cls">2024-12-01</th>
                    </tr>
                    <tr class="trcls">
                        <th class="thcls">•第二届中俄岩土与地下工程青年学者论坛顺利召开
                        </th>
                        <th class="th2cls">2024-11-27</th>
                    </tr>
                    <tr class="trcls">
                        <th class="thcls">•全国政协常委、人口资源环境委员会副主任、中国土木工程学会理事长易军到...
                        </th>
                        <th class="th2cls">2024-11-14</th>
                    </tr>
                </div>
                <img src="https://www.cces-tuwb.com/templets/default/images/metting_pic.jpg" width="100%" />
            </div>
            <div
                style="width: 40%;height: 470px;border-left: 1px #dddddd solid;border-right: 1px #dddddd solid;border-bottom: 1px #dddddd solid;">
                <div
                    style="display: flex;justify-content: space-between; height: 40px;line-height: 40px;background-color: #eeeeee;flex-direction: row-reverse;border-top: 2px solid #004848;">
                    <!-- <div class="industry_trends_tit">行业动态1</div> -->
                    <div class="industry_trends_tit" style="width:580px ;"><a href="#"
                            target="_blank">更多&nbsp;&gt;</a>通知公告</div>
                </div>
                <div>
                    <tr class="tr2cls bgcolor">
                        <th class="th3cls">•中国土木工程学会隧道及地下工程分会 第十一届理事会名誉职务人员<span class="right-time">2024-07-02</span>
                        </th>
                        <th class="th4cls"></th>
                    </tr>
                    <tr class="tr2cls">
                        <th class="th3cls">•中国土木工程学会隧道及地下工程分会 第十一届理事会组成名单 （2024 年 6 月 23 日通过<span
                                class="right-time">2024-07-02</span>
                        </th>
                    </tr>
                    <tr class="tr2cls bgcolor">
                        <th class="th3cls">•关于召开第十一届换届大会暨第七期隧道及地下工程科技创新讲坛的通知<span class="right-time">2024-05-22</span>
                        </th>
                    </tr>
                    <tr class="tr2cls">
                        <th class="th3cls">•关于第十届国际隧道与地下空间协会（ITA）隧道工程奖申报的通知<span class="right-time">2024-04-26</span>
                        </th>
                    </tr>
                    <tr class="tr2cls bgcolor">
                        <th class="th3cls">•关于召开第十届第十次常务理事(扩大)会议的通知<span class="right-time">2024-01-29</span>
                        </th>
                    </tr>
                    <tr class="tr2cls">
                        <th class="th3cls">•关于举办第六期隧道及地下工程科技创新讲坛的通知<span class="right-time">2023-11-21</span>
                        </th>
                    </tr>
                    <tr class="tr2cls bgcolor">
                        <th class="th3cls">•2024 年第 二十 二 届海峡两岸隧道与地下工程学术与技术研讨会 征 文 通知<span
                                class="right-time">2023-11-16</span>
                        </th>

                    </tr>
                </div>
            </div>
        </div>
        <div style="display: flex;width: 1000px;text-align:center;margin-top: 10px;">
            <div style="width: 60%;height: 420px;padding-right: 15px;">
                <div
                    style="display: flex;justify-content: space-between; height: 40px;line-height: 40px;background-color: #eeeeee;flex-direction: row-reverse;border-top: 2px solid #004848;">
                    <div class="industry_trends_tit" style="width:590px ;"><a href="#"
                            target="_blank">更多&nbsp;&gt;</a>学术活动</div>
                </div>
                <div>
                    <tr class="trcls">
                        <th class="thcls"> <i class="China">[国内]</i> •
                            中国土木工程学会隧道及地下工程分会十届七次常务理事（扩大）会议在长沙胜
                        </th>
                        <th class="th2cls">2022-07-21</th>
                    </tr>
                    <tr class="trcls">
                        <th class="thcls"><i class="China">[国内]</i> •
                            第十二届全国运营安全与节能环保的隧道及地下空间科技论暨第三届交通隧道工
                        </th>
                        <th class="th2cls">2021-12-31</th>
                    </tr>
                    <tr class="trcls">
                        <th class="thcls"><i class="China">[国内]</i> • 聚焦防水，共话未来 |
                            中国土木工程学会隧道及地下工程分会防水排水科技论
                        </th>
                        <th class="th2cls">2021-11-12</th>
                    </tr>
                    <tr class="trcls">
                        <th class="thcls"><i class="China">[国内]</i> •
                            第三届隧道及地下工程检测与监测国际研讨会暨第七届中国土木工程学会隧道及
                        </th>
                        <th class="th2cls">2021-10-27</th>
                    </tr>
                    <tr class="trcls">
                        <th class="thcls"><i class="China">[国内]</i> •
                            2018中国隧道与地下工程大会暨中国土木工程学会隧道及地下工程分会第二十届
                        </th>
                        <th class="th2cls">2018-11-13</th>
                    </tr>
                    <tr class="trcls">
                        <th class="thcls"><i class="China">[国际]</i> • “2018中马隧道大会”会议圆满结束”
                        </th>
                        <th class="th2cls">2018-10-25</th>
                    </tr>
                    <tr class="trcls">
                        <th class="thcls"><i class="China">[国际]</i> • 改革开放四十年中国隧道科技高峰论坛在广州举行
                        </th>
                        <th class="th2cls">2018-09-18</th>
                    </tr>
                    <tr class="trcls">
                        <th class="thcls"><i class="China">[国内]</i> • 特长隧道面临的技术挑战研讨会圆满结束
                        </th>
                        <th class="th2cls">2018-05-31</th>
                    </tr>
                    <tr class="trcls">
                        <th class="thcls"><i class="China">[国内]</i> • 全国科技创新大会 两院院士大会 中国科协第九次全国代表大会在京召开
                        </th>
                        <th class="th2cls">2016-06-01</th>
                    </tr>
                    <tr class="trcls">
                        <th class="thcls"><i class="China">[国内]</i> •
                            地下空间开发与历史文化名城保护研讨会暨中国土木工程学会隧道及地下工程分
                        </th>
                        <th class="th2cls">2016-01-04</th>
                    </tr>
                </div>
            </div>
            <div
                style="width: 40%;height: 420px;border-left: 1px #dddddd solid;border-right: 1px #dddddd solid;border-bottom: 1px #dddddd solid;">
                <div
                    style="display: flex;justify-content: space-between; height: 40px;line-height: 40px;background-color: #eeeeee;flex-direction: row-reverse;">
                    <div class="industry_trends_tit" style="background: #009a90;color: #ffffff;"><a href="#"
                            target="_blank" style="color: #ffffff;">更多&nbsp;&gt;</a>隧道工程</div>
                </div>
                <div style="background-color: #f6f6f6;padding-left: 10px;">
                    <tr class="trcls">
                        <th class="thcls"><i class="China">[中国]</i> • 截止2021年底中国铁路隧道情况统计
                        </th>
                        
                    </tr>
                    <tr class="trcls">
                        <th class="thcls"><i class="China">[中国]</i> • 2020年中国公路隧道数量、长度及扩建趋势分
                        </th>
                        
                    </tr>
                    <tr class="trcls">
                        <th class="thcls"><i class="China">[中国]</i> • 中国隧道与地下工程修建技术科普手册（一）
                        </th>
                        
                    </tr>
                    <tr class="trcls">
                        <th class="thcls"><i class="China">[国际]</i> • 磁悬浮中央新干线第一首都圈隧道北品川标段
                        </th>
                        
                    </tr>
                    <tr class="trcls">
                        <th class="thcls"><i class="China">[国际]</i> • 57公里！世界最长铁路隧道“圣哥达隧道”
                        </th>
                        
                    </tr>
                    <tr class="trcls">
                        <th class="thcls"><i class="China">[中国]</i> • 中国铁路隧道数据统计
                        </th>
                        
                    </tr>
                    <tr class="trcls">
                        <th class="thcls"><i class="China">[中国]</i> • 中国公路隧道数据统计
                        </th>
                        
                    </tr>
                    <tr class="trcls">
                        <th class="thcls"><i class="China">[国际]</i> • 日本阪神高速采用矩形盾构施工匝道
                        </th>
                        
                    </tr>
                    <tr class="trcls">
                        <th class="thcls"><i class="China">[国际]</i> • 土耳其伊斯坦布尔开通首条海底公路隧道
                        </th>
                        
                    </tr>
                    <tr class="trcls">
                        <th class="thcls"><i class="China">[国际]</i> • 世界最长最深的铁路隧道正式通车
                        </th>
                        
                    </tr>
                </div>
            </div>
        </div>
        <div style="width: 1000px;">
            <div
                style="display: flex;justify-content: space-between;  height: 40px;line-height: 40px;background-color: #7f899b;padding: 0 30px;">
                <div style="color:#ffffff">友情链接</div>
                <div style="color:#ffffff">更多 ></div>
            </div>
            <div style="background-color: #f0f0f0;height: 130px;display: flex;justify-content: space-around;">
                <div style="margin-top: 20px;display: flex;flex-direction: column;">
                    <img src="https://www.cces-tuwb.com/uploads/flink/1510011624512233.jpg" width="190px"
                        height="60px" />
                    <a href="http://123.57.212.98/html/tm/" target=“_blank”>中国土木工程协会</a>
                </div>
                <div style="margin-top: 20px;display: flex;flex-direction: column;">
                    <img src="https://www.cces-tuwb.com/uploads/flink/1512010816529230.png" width="190px"
                        height="60px" />
                    <a href="http://www.ita-aites.org/" target=“_blank”>国籍隧道与地下空间协会</a>
                </div>
                <div style="margin-top: 20px;display: flex;flex-direction: column;">
                    <img src="https://www.cces-tuwb.com/uploads/flink/1703301645581811.png" width="190px"
                        height="60px" />
                    <a href="http://meeting.mof.gov.cn/" target=“_blank”>党政机关会议定点场所管理信</a>
                </div>
                <div style="margin-top: 20px;display: flex;flex-direction: column;">
                    <img src="	https://www.cces-tuwb.com/uploads/flink/20180322114045.jpg" width="190px"
                        height="60px" />
                    <a href="http://www.crtg.com/" target=“_blank”>中铁隧道局集团有限公司</a>
                </div>
                <div style="margin-top: 20px;display: flex;flex-direction: column;">
                    <img src="https://www.cces-tuwb.com/uploads/flink/1510011658244539.jpg" width="190px"
                        height="60px" />
                    <a href="http://www.swi.com.cn/" target=“_blank”>中铁西南科学研究所</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {

}

</script>

<style scoped>
.cls {
    width: 1000px;
    margin: 0 auto;
    font-family: "\5B8B\4F53", Arial, "Microsoft Yahei", sans-serif;
}

.xingIcon {
    width: 8px;
    height: 8px;
    vertical-align: middle;
    margin-right: 10px;
}


.thcls {
    height: 35px;
    line-height: 35px;
    font-weight: 450;
    cursor: pointer;
}

.trcls:hover {
    background: #E3F0EF;
}

.th2cls {
    font-size: 12px;
    line-height: 35px;
    color: #999;
    height: 35px;
    font-weight: 200;
    margin-right: 10px;
}

.th3cls {
    height: 40px;
    line-height: 20px;
    font-weight: 450;
    text-align: left;
    font-size: 12px;
}

.th4cls {
    font-size: 12px;
    line-height: 40px;
    height: 40px;
    color: red;
    font-weight: 200;
}

.bgcolor {
    background-color: #f5f5f5;
}

.trcls {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px #dddddd dashed;
}

.tr2cls {
    margin: 10px auto;
    padding: 5px;
    display: flex;
    justify-content: space-between;
}

.va-middle {
    vertical-align: middle;
}

.industry_trends_tit {
    width: 400px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
}

.right-time {
    font-weight: bold !important;
    color: #d00;
}

.China {
    font-weight: bold;
    color: #004848;
    padding-right: 10px;
    font-style: normal;
    font-family: "\5B8B\4F53", Arial, "Microsoft Yahei", sans-serif;
}
</style>
