import Vue from 'vue'
import Antd from 'ant-design-vue'
import App from './App'
import 'ant-design-vue/dist/antd.css'

import "../src/assets/css/index_cn_page.css";
import "../src/assets/css/index_cn.css";


import router from '@/routes/index'
Vue.config.productionTip = false
Vue.use(Antd)
new Vue({
  router, // 在 Vue 实例中注册路由
  render: h => h(App),
}).$mount('#app')